import * as Sentry from "@sentry/nextjs";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { type ReactElement, useEffect, useState } from "react";
import { Toaster } from "~/components/toast";
import { useSessionData } from "~/hooks/session";
import "~/styles/global.css";
import { AbilityContext, buildAbilityFor } from "~/utils/permissions";
import { trpc } from "~/utils/trpc";
type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactElement;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};
function App({
  Component,
  pageProps
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);
  return <RouterReadyGuard data-sentry-element="RouterReadyGuard" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <title>Mon WBI</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
      </Head>
      <Toaster style={{
      "--width": "400px"
    } as React.CSSProperties} data-sentry-element="Toaster" data-sentry-source-file="_app.tsx" />
      <Auth data-sentry-element="Auth" data-sentry-source-file="_app.tsx">{getLayout(<Component {...pageProps} />)}</Auth>
    </RouterReadyGuard>;
}
const RouterReadyGuard = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [hasMounted, setHasMounted] = useState(false);
  const {
    isReady
  } = useRouter();

  // !! Careful !! This "breaks" the first static pass of next.js because we always return null until react is initialise so after hydration but it is ok for us since we only need it to work client side.
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  if (!isReady) {
    return <div className="grid h-screen w-screen items-center justify-center">Waiting for router to be ready...</div>;
  }
  return <>{children}</>;
};
function Auth({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    status,
    data: sessionData
  } = useSessionData();
  useEffect(() => {
    if (sessionData?.user) {
      Sentry.setUser({
        email: sessionData.user.email,
        id: sessionData.user.id,
        roles: sessionData.user.roles
      });
    } else {
      Sentry.setUser(null);
    }
  }, [sessionData?.user]);
  if (status === "loading") {
    return <div>Loading...</div>;
  }
  const ability = buildAbilityFor(sessionData?.user);
  return <AbilityContext.Provider value={ability} data-sentry-element="unknown" data-sentry-component="Auth" data-sentry-source-file="_app.tsx">{children}</AbilityContext.Provider>;
}
export default trpc.withTRPC(App);