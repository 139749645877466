import type { Gender, Role } from "~/types/database";
import type { UsersTab } from "~/types/users";

export const USERS_TABS = {
  INTERNAL_USERS: "internal-users",
  EXTERNAL_USERS: "external-users",
  ORGANISATIONS: "organismes",
} as const;

export const USERS_TAB_LABELS: Record<UsersTab, string> = {
  [USERS_TABS.INTERNAL_USERS]: "Utilisateurs internes",
  [USERS_TABS.EXTERNAL_USERS]: "Utilisateurs externes",
  [USERS_TABS.ORGANISATIONS]: "Organismes",
};

export const APPLICANT_ROLE = {
  APPLICANT: "applicant",
} as const;

export const WBI_HIERARCHY_INDEPENDENT_ROLES = {
  ACCOUNTANT: "accountant",
  ADMIN: "admin",
  AUTHORIZING_OFFICER: "authorizing_officer",
  COMMITMENT_CONTROLLER: "commitment_controller",
  FINANCIAL_INSPECTOR: "financial_inspector",
  MINISTER: "minister",
} as const;

export const WBI_SERVICE_DEPENDENT_ROLES = {
  HANDLER: "handler",
  HANDLER_MANAGER: "handler_manager",
  SERVICE_HEAD: "service_head",
} as const;

export const WBI_SUB_DEPARTMENT_DEPENDENT_ROLES = {
  SUB_DEPARTMENT_HEAD: "sub_department_head",
} as const;

export const WBI_DEPARTMENT_DEPENDENT_ROLES = {
  DEPARTMENT_HEAD: "department_head",
} as const;

const ROLES = {
  ...APPLICANT_ROLE,
  ...WBI_HIERARCHY_INDEPENDENT_ROLES,
  ...WBI_SERVICE_DEPENDENT_ROLES,
  ...WBI_SUB_DEPARTMENT_DEPENDENT_ROLES,
  ...WBI_DEPARTMENT_DEPENDENT_ROLES,
};

const STATIC_ROLES: Record<Role["type"], string> = {
  [ROLES.ACCOUNTANT]: "Agent comptable",
  [ROLES.ADMIN]: "Admin",
  [ROLES.APPLICANT]: "Demandeur",
  [ROLES.AUTHORIZING_OFFICER]: "Ordonnateur",
  [ROLES.COMMITMENT_CONTROLLER]: "Contrôleur des engagements",
  [ROLES.FINANCIAL_INSPECTOR]: "Inspecteur des finances",
  [ROLES.HANDLER]: "Agent traitant",
  [ROLES.HANDLER_MANAGER]: "Agent traitant étendu",
  [ROLES.MINISTER]: "Ministre",
  [ROLES.SERVICE_HEAD]: "Chef de service",
  [ROLES.SUB_DEPARTMENT_HEAD]: "Chef de sous-département",
  [ROLES.DEPARTMENT_HEAD]: "Chef de département",
};

export const WBI_INTERNAL_ASSIGNABLE_ROLES = {
  [ROLES.HANDLER]: "Agent traitant",
  [ROLES.HANDLER_MANAGER]: "Agent traitant étendu",
  [ROLES.SERVICE_HEAD]: "Chef de service",
  [ROLES.SUB_DEPARTMENT_HEAD]: "Chef de sous-département",
  [ROLES.DEPARTMENT_HEAD]: "Chef de département",
  [ROLES.COMMITMENT_CONTROLLER]: "Contrôleur des engagements",
  [ROLES.FINANCIAL_INSPECTOR]: "Inspecteur des finances",
  [ROLES.AUTHORIZING_OFFICER]: "Ordonnateur",
  [ROLES.MINISTER]: "Ministre",
  [ROLES.ADMIN]: "Admin",
  [ROLES.ACCOUNTANT]: "Agent comptable",
};

export const WBI_INTERNAL_ASSIGNABLE_ROLE_OPTIONS = Object.entries(WBI_INTERNAL_ASSIGNABLE_ROLES).map(([value]) => ({
  id: value,
  label: STATIC_ROLES[value as Role["type"]],
}));

const USER_BADGE_STYLES = [
  { backgroundColor: "bg-royal-blue" },
  { backgroundColor: "bg-sky-blue" },
  { backgroundColor: "bg-blue-grey" },
  { backgroundColor: "bg-aubergine" },
  { backgroundColor: "bg-purple" },
  { backgroundColor: "bg-sparkling-grape" },
  { backgroundColor: "bg-brown" },
  { backgroundColor: "bg-bordeau" },
  { backgroundColor: "bg-cherry-red" },
] as const;

const USER_GENDER = {
  MEN: "M",
  WOMEN: "F",
} as const;

const STATIC_USER_GENDER: Record<Gender, string> = {
  [USER_GENDER.MEN]: "Homme",
  [USER_GENDER.WOMEN]: "Femme",
};

const USER_GENDER_OPTIONS: { id: Gender; label: string }[] = Object.entries(STATIC_USER_GENDER).map((entry) => ({ id: entry[0] as Gender, label: entry[1] }));

export const STATUS = {
  TRUE: "true",
  FALSE: "false",
} as const;

const STATIC_STATUS: Record<"true" | "false", string> = {
  true: "Actif",
  false: "Inactif",
};

export { ROLES, STATIC_ROLES, USER_BADGE_STYLES, USER_GENDER, STATIC_USER_GENDER, USER_GENDER_OPTIONS, STATIC_STATUS };
